var columns = [{
  title: '合集编号',
  dataIndex: 'id',
  key: 'id',
  width: '12%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '合集名称',
  dataIndex: 'name',
  key: 'name',
  width: '12%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: 'slug',
  dataIndex: 'slug',
  key: 'slug',
  width: '14%',
  scopedSlots: {
    customRender: 'slug'
  }
}, {
  title: '合约地址',
  dataIndex: 'contract_address',
  key: 'contract_address',
  width: '20%',
  scopedSlots: {
    customRender: 'address'
  }
}, {
  title: '来源',
  dataIndex: 'source',
  key: 'source',
  width: '10%',
  scopedSlots: {
    customRender: 'source'
  }
}, {
  title: '是否计算稀有度',
  dataIndex: 'is_fixed_nft_quantity',
  key: 'is_fixed_nft_quantity',
  width: '10%',
  scopedSlots: {
    customRender: 'is_fixed_nft_quantity'
  }
}, {
  title: '所属分类',
  dataIndex: 'category_name',
  key: 'category_name',
  width: '15%',
  scopedSlots: {
    customRender: 'category_name'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  //   fixed: "right",
  width: '20%',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };