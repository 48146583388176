var searchKeys = [{
  key: "collection_name",
  label: "合集名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "contract_address",
  label: "合约地址",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "source",
  label: "来源",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "is_fixed_nft_quantity",
  label: "是否计算稀有度",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "category_id",
  label: "所属分类",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true,
  initialValue: ''
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };